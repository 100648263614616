const RATING_QUERY = /* GraphQL */ `
  query Rating($id: uuid!) {
    productReviews {
      __typename
      id
      message
      createdAt
      user {
        username
      }
    }
    productReviewsAggregate(where: { productId: { _eq: $id } }) {
      aggregate {
        avg {
          rating
        }
        max {
          rating
        }
        count
      }
    }
  }
`;

export default RATING_QUERY;
