import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import SVG from "./SVG";

const Trash = ({ className, ...props }) => (
  <SVG aria-label="Trash" className={twMerge("w-3 h-3", className)} {...props}>
    <path
      d="M3 6H5M5 6H21M5 6V20C5 20.5304 5.21071 21.0391 5.58579 21.4142C5.96086 21.7893 6.46957 22 7 22H17C17.5304 22 18.0391 21.7893 18.4142 21.4142C18.7893 21.0391 19 20.5304 19 20V6H5ZM8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVG>
);

Trash.propTypes = {
  className: PropTypes.string,
};

export default Trash;
