import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import SVG from "./SVG";

const Star = ({ className, strokeWidth, ...props }) => (
  <SVG
    aria-label="Star"
    className={twMerge("w-3 h-3 fill-gold", className)}
    {...props}
  >
    <path
      d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVG>
);

Star.propTypes = {
  className: PropTypes.string,
  strokeWidth: PropTypes.string,
};

export default Star;
