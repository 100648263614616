import { memo } from "react";
import PropTypes from "prop-types";
import dynamic from "next/dynamic";

import RATING_QUERY from "@sholdi/graphql/queries/rating";
import SHOP_RATING_QUERY from "@sholdi/graphql/queries/shopRatingQuery";

import useQueryHook from "@sholdi/shared/lib/urql/useQueryHook";

import {
  PRODUCT_REVIEWS_TYPE,
  SHOP_REVIEWS_TYPE,
} from "@sholdi/shared/constants/customerReviews";

import Rating from "../Rating";

const RatingsDescriptions = dynamic(() => import("./RatingsDescriptions"));

const RecordRating = ({
  modelId,
  type,
  children,
  showRatingDescription,
  ratingType,
  ...props
}) => {
  const isShop = type === SHOP_REVIEWS_TYPE;

  const { data } = useQueryHook(isShop ? SHOP_RATING_QUERY : RATING_QUERY, {
    skip: !modelId,
    variables: {
      id: modelId,
    },
  });

  const {
    [isShop ? "shopReviewsAggregate" : "productReviewsAggregate"]: {
      aggregate: {
        avg: { rating },
      },
    } = {
      aggregate: {
        avg: { rating: 0 },
      },
    },
  } = data || {};

  if (rating && rating !== 0) {
    return (
      <Rating value={rating} {...props}>
        {children}
        {showRatingDescription && (
          <RatingsDescriptions rating={rating} ratingType={ratingType} />
        )}
      </Rating>
    );
  }
  return null;
};

RecordRating.propTypes = {
  modelId: PropTypes.string.isRequired,
  type: PropTypes.oneOf([PRODUCT_REVIEWS_TYPE, SHOP_REVIEWS_TYPE]).isRequired,
  children: PropTypes.node,
  ratingType: PropTypes.string,
  showRatingDescription: PropTypes.bool,
};

export default memo(RecordRating);
