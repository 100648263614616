const transformer = product => {
  const {
    id = product.product_id,
    productDescriptions = [
      {
        name: product.name,
        slug: product.slug,
        localeKey: product.localeKey,
      },
    ],
    productImages = [],
    productTags = [],
    productCombination = {
      price: product.price,
      salePrice: product.sale_price,
      availableStock: 0,
      id: product.productCombinationId,
      settings: {
        url: product.external_url,
      },
    },
    shopId = product.shop_id,
    category = product.category_1
      ? {
          categoryDescriptions: [
            {
              name: product.category_1,
              localeKey: product.localeKey,
            },
          ],
        }
      : null,
    catalogItemId = null,
    productCombinations = [],
    productCombinationsAggregate = { aggregate: { count: 0 } },
    typeKey = 'PHYSICAL',
    featured_image,
    productImage,
  } = product

  const [{ name, slug, localeKey }] = productDescriptions

  // More robust image handling
  let mainImage = product.mainImage ?? null

  // Try to get image from different possible sources
  if (productImages.length > 0) {
    const image =
      productImages.find(productImage => productImage.isFeatured) || productImages[0]
    mainImage = image?.path || null
  } else if (featured_image) {
    mainImage = featured_image
  } else if (productImage?.path) {
    mainImage = productImage.path
  }

  // Ensure mainImage is always a string or null
  mainImage = typeof mainImage === 'string' ? mainImage : null

  const tags = productTags.map(productTag => productTag.tag.name)

  const {
    salePrice = 0,
    price = 0,
    availableStock = 0,
    id: combinationId = null,
    settings = {},
  } = productCombination ?? productCombinations?.[0] ?? {}

  const externalLink = settings.url ?? null

  const { categoryDescriptions = [] } = category || {}
  let categoryName = null

  if (categoryDescriptions.length) {
    categoryName = categoryDescriptions[0]?.name
  }

  return {
    id,
    name,
    slug,
    localeKey,
    mainImage,
    tags,
    price,
    salePrice,
    availableStock,
    combinationId: combinationId ?? product.combinationId ?? null,
    // ucis,
    // isExternal,
    externalLink,
    shopId,
    // shopName: shop.name,
    categoryName,
    catalogItemId,
    typeKey: typeKey ?? 'PHYSICAL',
    combinationCount: productCombinationsAggregate?.aggregate?.count ?? 0,
  }
}

export default transformer

// for product page
export const revertTransformer = product => {
  const {
    id,
    name,
    slug,
    localeKey,
    mainImage,
    tags,
    productCombination,
    shopId,
    shopName,
    categoryName,
    catalogItemId,
  } = product

  return {
    id,
    productDescriptions: [{ name, localeKey, slug }],
    productImages: [{ path: mainImage, isFeatured: true }],
    productTags: tags.map(tag => ({ tag: { name: tag } })),
    productCombination: productCombination,
    productCombinations: [productCombination],
    shop: { id: shopId, name: shopName },
    shopId,
    category: {
      categoryDescriptions: [{ name: categoryName, localeKey }],
    },
    catalogItemId,
    productCombinationsAggregate: { aggregate: { count: 1 } },
  }
}
