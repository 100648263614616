import { useState, useMemo } from "react";
import PropTypes from "prop-types";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { twMerge } from "tailwind-merge";

import IconButton from "@sholdi/primitives/atoms/IconButton";
import Trash from "@sholdi/icons/Trash";
import Heart from "@sholdi/icons/Heart";

const AddToFavouritesModal = dynamic(
  () => import("../../organisms/AddToFavouritesModal"),
  {
    ssr: false,
  },
);
const RemoveProductFromWishlistModal = dynamic(
  () => import("../../molecules/RemoveProductFromWishlistModal"),
  {
    ssr: false,
  },
);

const ProductCardActionsActivator = ({ product, className }) => {
  const [isMenuVisible, setMenuVisible] = useState(false);

  const { pathname } = useRouter();

  const showAddItemToWishlistModal = pathname !== "/wishlists/[id]/[name]";

  const ModalContent = useMemo(
    () =>
      showAddItemToWishlistModal
        ? { Component: AddToFavouritesModal, Activator: Heart }
        : { Component: RemoveProductFromWishlistModal, Activator: Trash },
    [showAddItemToWishlistModal],
  );

  return (
    <>
      <IconButton
        onClick={() => setMenuVisible(true)}
        className={twMerge(
          "absolute top-0 right-0 z-30 text-error-main size-7",
          className,
        )}
      >
        <ModalContent.Activator />
      </IconButton>
      {isMenuVisible && (
        <ModalContent.Component
          product={product}
          open={isMenuVisible}
          onToggle={setMenuVisible}
        />
      )}
    </>
  );
};

ProductCardActionsActivator.propTypes = {
  product: PropTypes.object,
  className: PropTypes.string,
};

ProductCardActionsActivator.displayName = "ProductCard";

export default ProductCardActionsActivator;
