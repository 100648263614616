import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import Text from "../Text";

export const Line = ({ className, ...props }) => (
  <Text
    className={twMerge(
      "bg-gray-50 overflow-hidden relative h-3 rounded-sm animate-pulse",
      className,
    )}
    {...props}
  />
);

Line.propTypes = {
  className: PropTypes.string,
};

Line.displayName = "SkeletonLine";

export default Line;
