const SHOP_RATING_QUERY = /* GraphQL */ `
  query ShopRating($id: uuid!) {
    shopReviewsAggregate(where: { shopId: { _eq: $id } }) {
      aggregate {
        avg {
          rating
        }
        max {
          rating
        }
        count
      }
    }
  }
`;

export default SHOP_RATING_QUERY;
