import { memo, useMemo } from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import Star from "@sholdi/icons/Star";
import Text from "@sholdi/primitives/atoms/Text";

const sizes = [
  {
    val: "xs",
    num: "size-4",
  },
  {
    val: "sm",
    num: "size-6",
  },
  {
    val: "md",
    num: "size-8",
  },
  { val: "lg", num: "size-10" },
];
function Rating({
  size = "sm",
  value,
  label,
  activeClassName = "fill-gold",
  inactiveClassName = "fill-gray-50",
  count = 5,
  direction = "ltr",
  children,
  className,
  ...props
}) {
  const properSize = useMemo(() => sizes.find((s) => s.val === size), [size]);

  const isLeftToRight = direction === "ltr";
  const flexDirection = isLeftToRight ? "flex-row" : "flex-row-reverse";

  return (
    <div className={twMerge("flex", className)} {...props}>
      <div className={twMerge("hidden lg:flex", flexDirection)}>
        {label && (
          <Text className={twMerge(isLeftToRight ? "mr-4" : "ml-4")}>
            {label}
          </Text>
        )}
        {Array(count)
          .fill(0)
          .map((_, i) => (
            <Star
              key={i}
              aria-hidden="true"
              focusable={false}
              className={twMerge(
                "stroke-0",
                properSize.num,
                value > i
                  ? (activeClassName ?? "fill-gold")
                  : (inactiveClassName ?? "fill-gray-50"),
              )}
            />
          ))}
      </div>
      {children}
    </div>
  );
}

Rating.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.oneOfType([
      PropTypes.shape({
        val: PropTypes.string,
        num: PropTypes.number,
        fontSize: PropTypes.string,
      }),
    ]),
    PropTypes.string,
    PropTypes.number,
  ]),
  activeClassName: PropTypes.string,
  inactiveClassName: PropTypes.string,
  children: PropTypes.node,
  label: PropTypes.node,
  count: PropTypes.number,
  direction: PropTypes.oneOf(["ltr", "rtl"]),
  className: PropTypes.string,
};

export default memo(Rating);
