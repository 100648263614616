import { memo } from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";
import { cva } from "class-variance-authority";

import Times from "@sholdi/icons/Times";

import Box from "../Box";
import Image from "../Image";
import IconButton from "../IconButton";

// Define Tailwind-based variants for ChipBase
const chipBaseClass = cva(
  "w-fit flex items-center font-medium leading-9 font-heading",
  {
    variants: {
      variant: {
        circleSm: "rounded-full p-1 w-5 h-5 justify-center",
        circleMd: "rounded-full p-1.5 w-6 h-6 justify-center",
        circleLg: "rounded-full p-2 w-7 h-7 justify-center",
        xs: "rounded-full py-0.5 px-1.5 text-xs leading-9",
        sm: "rounded-md py-0.5 px-2 text-xs",
        md: "rounded-md py-0.5 px-2 text-sm",
        lg: "rounded-md py-1 px-3 text-sm",
      },
      radius: {
        diagonal: "rounded-none rounded-tl-default rounded-br-default",
        diagonalReverse: "rounded-none rounded-bl-default rounded-tr-default",
      },
    },
    defaultVariants: {
      variant: "md",
    },
  },
);

const bgClasses = {
  primary: "bg-primary-lightest text-primary-dark",
  secondary: "bg-secondary-lightest text-secondary-dark",
  success: "bg-success-lightest text-success-dark",
  warning: "bg-warning-lightest text-warning-dark",
  error: "bg-error-lightest text-error-dark",
  gray: "bg-gray-main text-white",
  primaryInverted: "bg-primary-main text-white",
  errorInverted: "bg-error-main text-white",
  grayLight: "bg-gray-light text-white",
};

const bgInverseClasses = {
  primary: "bg-primary-dark text-primary-lightest",
  secondary: "bg-secondary-dark text-secondary-lightest",
  success: "bg-success-dark text-success-lightest",
  warning: "bg-warning-dark text-warning-lightest",
  error: "bg-error-dark text-error-lightest",
};

const Chip = ({
  variant,
  color = "primary",
  inverse,
  children,
  isRemovable,
  onRemove,
  image,
  radius,
  className,
  ...props
}) => {
  const styleClassName = inverse
    ? bgInverseClasses[color]
    : bgClasses[color] || bgClasses.primary;

  return (
    <Box
      as="div"
      className={twMerge(
        chipBaseClass({ variant, radius }),
        styleClassName,
        className || "",
      )}
      {...props}
    >
      {image && (
        <Image
          src={image}
          className="h-4 w-4 mr-1 rounded-full"
          alt="chipImage"
        />
      )}
      {children}
      {isRemovable && (
        <IconButton
          onClick={onRemove}
          className="ml-2 flex-none text-current size-4"
          // style={{ width: iconSize, height: iconSize }}
        >
          <Times />
        </IconButton>
      )}
    </Box>
  );
};

Chip.displayName = "Chip";

Chip.propTypes = {
  variant: PropTypes.oneOf([
    "none",
    "xs",
    "sm",
    "md",
    "lg",
    "circleSm",
    "circleMd",
    "circleLg",
  ]),
  inverse: PropTypes.bool,
  color: PropTypes.string,
  children: PropTypes.node,
  isRemovable: PropTypes.bool,
  onRemove: PropTypes.func,
  image: PropTypes.string,
  className: PropTypes.string,
  fontColor: PropTypes.string,
  iconSize: PropTypes.number,
  radius: PropTypes.string,
};

export default memo(Chip);
